import React from 'react';
import './Footer.css';
import FooterLogo from '../assets/logos/footer_logo.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <img src={FooterLogo} alt="Logo" className="footer-logo" />
          <p>Copyright © 2023 Re:String Guitar Ensemble</p>
        </div>
        <div className="footer-section footer-social">
          <a href="https://www.instagram.com/restring.ge/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          <a href="https://www.youtube.com/@ReStringGuitarEnsemble" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
          <a href="https://www.tiktok.com/@restringge" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Designed with ❤️ by Chairperson Ong Chee Wei</p>
      </div>
    </footer>
  );
};

export default Footer;
