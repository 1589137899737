import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from 'react-router-dom';
import HomePage from './HomePage';
import JoinUs from './JoinUs';
import AboutUs from './AboutUs';
import { ReactComponent as NavbarLogo } from './assets/logos/navbar_logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import './App.css';
import { Collapse } from 'bootstrap';

const AppRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route path="/" element={<HomePage key={location.pathname} />} />
      <Route path="/join-us" element={<JoinUs />} />
      <Route path="/about-us" element={<AboutUs />} />
    </Routes>
  );
};

const App = () => {
  const handleNavItemClick = () => {
    const navbarCollapse = document.getElementById('navbarNav');
    if (navbarCollapse && navbarCollapse.classList.contains('show')) {
      new Collapse(navbarCollapse).hide();
    }
  };

  return (
    <div className="App">
      <Router>
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
          <div className="container-fluid px-3">
            <Link to="/" className="navbar-brand d-flex align-items-center" onClick={handleNavItemClick}>
              <NavbarLogo className="navbar-logo" style={{ height: '40px', width: 'auto' }} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded={false}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/about-us" className="nav-link" onClick={handleNavItemClick}>About Us</Link>
                </li>
                <li className="nav-item">
                  <Link to="/join-us" className="nav-link" onClick={handleNavItemClick}>Join Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <AppRoutes />
      </Router>
    </div>
  );
};

export default App;
