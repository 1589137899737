import React from 'react';
import './AboutUs.css'; // Import the CSS file for styling

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-background"></div>
      <div className="about-us-overlay"></div>
      <h1>About Us</h1>
      <p>Welcome to Re:String Guitar Ensemble! We are a Niibori Guitar Ensemble based in Singapore. Whether you're a seasoned guitarist or just starting out, our doors are open to everyone who shares a passion for playing the guitar.</p>
      <h2>Our History</h2>
      <p>
      Founded with roots in the NTU Guitar Ensemble, our club is a harmonious community where guitar enthusiasts of all levels come together to create beautiful music. Originally formed by a group of NTU Guitar Ensemble alumni who wanted to continue playing together, our ensemble has since grown and opened its doors to the public, welcoming members from all walks of life.
      </p>
      <h2>Our Values</h2>
      <p>
        Our ensemble focuses on the joy of making music collaboratively. We believe in creating an environment that is both enjoyable and forgiving, where mistakes are viewed as stepping stones to improvement rather than setbacks. Patience is at the heart of our philosophy, ensuring that each member feels comfortable and supported throughout their musical journey.
      </p>
      <p>
        Growth is a fundamental pillar of our club. We strive to help each member develop their skills, not just individually, but as a cohesive group. Through regular practice sessions, performances, and a supportive community, we nurture both personal and collective growth.
      </p>
      <h2>How We Operate</h2>
      <p>
      We frequently hold recording sessions and organize concerts every one or two years, showcasing our collective effort and hard work. We encourage members to take an active role in the club, whether by managing an event, such as a recording session, or proposing their own arrangements for the group to play.
      </p>
      <h2>Contact Us</h2>
      <p>Join us at Re:String Guitar Ensemble, where every strum brings us closer together and every note resonates with the spirit of camaraderie and musical excellence. Let's create unforgettable melodies and grow together as musicians and friends. If you have any questions or would like to join us, please don't hesitate to reach out at our Join Us page!</p>
    </div>
  );
};

export default AboutUs;
