import React from 'react';
import noiseImage from '../assets/images/noise.jpg';

const LandscapePolaroid = ({ imageUrl, caption }) => (
  <svg className="polaroid-svg" viewBox="0 0 557 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <rect x="6" y="4" width="541" height="436.017" rx="5" fill="#FFFEFC"/>
    </g>
    <g>
      <image href={imageUrl} x="20" y="30" width="513.91" height="342.648" preserveAspectRatio="xMidYMid slice"/>
    </g>
    <g filter="url(#filter1_i)">
      <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="20" y="30" width="514" height="343">
        <rect x="20" y="30" width="513.48" height="342.987" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0)">
        <rect x="-9.115" y="-11.371" width="571.71" height="572.824" fill="url(#pattern1)" fillOpacity="0.1" style={{ mixBlendMode: 'soft-light' }}/>
      </g>
    </g>
    <text x="50%" y="410" textAnchor="middle" fill="#333" fontSize="24" fontFamily="'Patrick Hand', cursive">{caption}</text>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="557" height="452.017" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="2" dy="4"/>
        <feGaussianBlur stdDeviation="4"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
        <image href={noiseImage} width="1" height="1" preserveAspectRatio="xMidYMid slice"/>
      </pattern>
    </defs>
  </svg>
);

export default LandscapePolaroid;
