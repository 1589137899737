import React, { useState } from 'react';
import './JoinUs.css'; // Import the CSS file
import emailjs from '@emailjs/browser';
import { ToastContainer, toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JoinUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    email: '',
    contactNumber: '',
    experience: '',
    grade: ''
  });

  const [modalMessage, setModalMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    // Validate contact number
    if (!/^\d{8}$/.test(formData.contactNumber)) {
      toast.error('Contact number must consist of exactly 8 digits.');
      return;
    }

    try {
      await emailjs.send(
        "restring2023",
        "template_nnj2rht",
        {
          from_name: formData.name,
          age: formData.age,
          from_email: formData.email,
          contact_number: formData.contactNumber,
          experience: formData.experience,
          grade: formData.grade
        },
        {
          publicKey: 'tLHsuO6T1NWk6oBrL',
          blockHeadless: true,
          limitRate: {
            // Allow 1 request per 10s
            throttle: 10000,
          },
        }
      )
      toast.success('Email has been sent successfully!');
    } 
    catch (err) {
      console.log(err)
      toast.error('Submission failed. Please wait and try again.');
    }
  };
  
  return (
    <div className="join-us-background">
      <div className="join-us-container">
        <h1>Join Us!</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="age">Age</label>
            <input
              type="number"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="contactNumber">Contact Number</label>
            <input
              type="number"
              id="contactNumber"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              required
              pattern="\d{8}"
              title="Contact number must be 8 digits"
            />
          </div>
          <div>
            <label htmlFor="experience">Duration of Guitar Experience</label>
            <select
              id="experience"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              required
            >
              <option value=""></option>
              <option value="0-1 year">0-1 year</option>
              <option value="1-3 years">1-3 years</option>
              <option value="3-5 years">3-5 years</option>
              <option value="5+ years">5+ years</option>
            </select>
          </div>
          <div>
            <label htmlFor="grade">Grade <span className="optional">(optional)</span></label>
            <input
              type="text"
              id="grade"
              name="grade"
              value={formData.grade}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
      <ToastContainer position={"bottom-right"} />
    </div>
  );
};

export default JoinUs;
