// UpcomingEvent.js
import React from 'react';
import { Link } from 'react-router-dom';
import './UpcomingEvent.css';

const UpcomingEvent = ({ event, onNext, onPrev }) => {
  return (
    <div className="upcoming-event">
      <div className="event-image-container">
        <img src={event.image} alt={event.title} className="event-image" />
      </div>
      <div className="event-details">
        <h2>{event.title}</h2>
        <h3>{event.subtitle}</h3>
        <p className="event-description">{event.description}</p>
        <Link to="/join-us" className="event-button">
          Register
        </Link>
        <div className="event-navigation">
          <button onClick={onPrev} className="nav-button">{'<'}</button>
          <button onClick={onNext} className="nav-button">{'>'}</button>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvent;
